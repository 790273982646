export function setCookie (cookieName: string, cookieValue: string, exdays: number) {
  const d = new Date()
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cookieName + '=' + cookieValue + ';' + expires + ';path=/'
}

export function getCookie (cookieName: string) {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')
  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function checkCookie (cookie: string) {
  const cookieName = getCookie(cookie)
  return cookieName !== ''
}

export function parseCookies (cookieString: string): Record<string, string> {
  const cookies: Record<string, string> = {}

  cookieString.split(';').forEach((cookie) => {
    const [key, value] = cookie.split('=').map(part => part.trim())
    cookies[key] = decodeURIComponent(value)
  })

  return cookies
}
