import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_j7wLaRg9eH from "/usr/src/app/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_IJZcPvBsSf from "/usr/src/app/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_t2GMTTFnMT from "/usr/src/app/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import click_outside_D2NiLGp7dJ from "/usr/src/app/plugins/click-outside.ts";
import gtag_client_Zw8EQXNVTz from "/usr/src/app/plugins/gtag.client.ts";
import gtm_client_OzmBuHLRIb from "/usr/src/app/plugins/gtm.client.ts";
import highcharts_client_MYKkaxDq22 from "/usr/src/app/plugins/highcharts.client.ts";
import i18n_config_chpUfOMRWg from "/usr/src/app/plugins/i18n.config.ts";
import init_client_lHDJZN4lq2 from "/usr/src/app/plugins/init.client.ts";
import vue_notification_client_3tTAjVhyyr from "/usr/src/app/plugins/vue-notification.client.ts";
import ym_client_nblh4y12FA from "/usr/src/app/plugins/ym.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  plugin_client_j7wLaRg9eH,
  plugin_IJZcPvBsSf,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  click_outside_D2NiLGp7dJ,
  gtag_client_Zw8EQXNVTz,
  gtm_client_OzmBuHLRIb,
  highcharts_client_MYKkaxDq22,
  i18n_config_chpUfOMRWg,
  init_client_lHDJZN4lq2,
  vue_notification_client_3tTAjVhyyr,
  ym_client_nblh4y12FA
]