import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl, handleErrors } from '~/api/common'

export const subscriptionPlanApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/subscription-plans',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getAllSubscriptionPlans () {
      const { data: payload } = await axiosBase.get('')

      return handleErrors(payload)
    }
  }
}
