import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

interface ICreateAmoEvent {
  details: any;
  type: string;
}

export const amoApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/amo',
    withCredentials: true,
    validateStatus (status) {
      // When all methods will be rewritten in monads, make this always return true(to don't throw exceptions)
      return status >= 200 && status < 500
    }
  })

  return {
    async createAmoEvent ({ type, details }: ICreateAmoEvent) {
      const { data: payload } = await axiosBase.post('/send-amo-event', {
        type,
        details
      })

      return handleErrors(payload)
    }
  }
}
