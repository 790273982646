import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const surveyApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/survey',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async submitSurvey (score: string, comment: string) {
      const { data: payload } = await axiosBase.put('/submit', { score, comment })

      return handleErrors(payload)
    },
    async getShowInfoSurvey () {
      const { data: payload } = await axiosBase.get('/get-show-info', { })

      return handleErrors(payload)
    }
  }
}
