import { abTestsApi } from '~/api/ab-tests.api'
import { actionsApi } from '~/api/actions.api'
import { amoApi } from '~/api/amo.api'
import { filesApi } from '~/api/files.api'
import { bridgeApi } from '~/api/bridge.api'
import { authApi } from '~/api/auth/auth.api'
import { userApi } from '~/api/user.api'
import { integrationsApi } from '~/api/integrations.api'
import { onboardingApi } from '~/api/onboarding.api'
import { settingsApi } from '~/api/settings.api'
import { statisticsApi } from '~/api/statistics.api'
import { statisticsInnerApi } from '~/api/statistics-inner.api'
import { subscriptionPlanApi } from '~/api/subscription-plan.api'
import { subscriptionsApi } from '~/api/subscriptions.api'
import { surveyApi } from '~/api/survey.api'
import { visitorsApi } from '~/api/visitors.api'
import { qrApi } from '~/api/qr.api'

export const useApi = () => {
  const config = useRuntimeConfig()

  return {
    abTestsApi: abTestsApi(config),
    actionsApi: actionsApi(config),
    amoApi: amoApi(config),
    filesApi: filesApi(config),
    bridgeApi: bridgeApi(config),
    authApi: authApi(config),
    userApi: userApi(config),
    integrationsApi: integrationsApi(config),
    onboardingApi: onboardingApi(config),
    settingsApi: settingsApi(config),
    statisticsApi: statisticsApi(config),
    statisticsInnerApi: statisticsInnerApi(config),
    subscriptionsApi: subscriptionsApi(config),
    subscriptionPlanApi: subscriptionPlanApi(config),
    surveyApi: surveyApi(config),
    visitorsApi: visitorsApi(config),
    qr: qrApi(config)
  }
}

export type Api = ReturnType<typeof useApi>
