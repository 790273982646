import { default as token2NdzR8ktHRMeta } from "/usr/src/app/pages/account/login/token.vue?macro=true";
import { default as editorrJ0mAlmaAuMeta } from "/usr/src/app/pages/actions/editor.vue?macro=true";
import { default as indexkv3ZaySHKBMeta } from "/usr/src/app/pages/actions/index.vue?macro=true";
import { default as appearance9B2tU4WReGMeta } from "/usr/src/app/pages/appearance.vue?macro=true";
import { default as bridgeJZVH4r3ClLMeta } from "/usr/src/app/pages/bridge.vue?macro=true";
import { default as integrationsX1s50LxNUQMeta } from "/usr/src/app/pages/integrations.vue?macro=true";
import { default as profilempmbOOM2vzMeta } from "/usr/src/app/pages/profile.vue?macro=true";
import { default as settingsnXXGFi4oVlMeta } from "/usr/src/app/pages/settings.vue?macro=true";
import { default as confirmi9FD4LdQJtMeta } from "/usr/src/app/pages/sign-in/confirm.vue?macro=true";
import { default as index3NvgM5Kg23Meta } from "/usr/src/app/pages/sign-in/index.vue?macro=true";
import { default as passwordW0EnsY8NqMMeta } from "/usr/src/app/pages/sign-in/password.vue?macro=true";
import { default as set_45password1d5jel0FykMeta } from "/usr/src/app/pages/sign-in/set-password.vue?macro=true";
import { default as tariffAkdTSci4zsMeta } from "/usr/src/app/pages/tariff.vue?macro=true";
import { default as ui_45kitEXADGPxFSDMeta } from "/usr/src/app/pages/ui-kit.vue?macro=true";
import { default as visitorsX3iLNNgWjbMeta } from "/usr/src/app/pages/visitors.vue?macro=true";
export default [
  {
    name: token2NdzR8ktHRMeta?.name ?? "account-login-token",
    path: token2NdzR8ktHRMeta?.path ?? "/account/login/token",
    meta: token2NdzR8ktHRMeta || {},
    alias: token2NdzR8ktHRMeta?.alias || [],
    redirect: token2NdzR8ktHRMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/account/login/token.vue").then(m => m.default || m)
  },
  {
    name: editorrJ0mAlmaAuMeta?.name ?? "actions-editor",
    path: editorrJ0mAlmaAuMeta?.path ?? "/actions/editor",
    meta: editorrJ0mAlmaAuMeta || {},
    alias: editorrJ0mAlmaAuMeta?.alias || [],
    redirect: editorrJ0mAlmaAuMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/actions/editor.vue").then(m => m.default || m)
  },
  {
    name: indexkv3ZaySHKBMeta?.name ?? "actions",
    path: indexkv3ZaySHKBMeta?.path ?? "/actions",
    meta: indexkv3ZaySHKBMeta || {},
    alias: indexkv3ZaySHKBMeta?.alias || [],
    redirect: indexkv3ZaySHKBMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/actions/index.vue").then(m => m.default || m)
  },
  {
    name: appearance9B2tU4WReGMeta?.name ?? "appearance",
    path: appearance9B2tU4WReGMeta?.path ?? "/appearance",
    meta: appearance9B2tU4WReGMeta || {},
    alias: appearance9B2tU4WReGMeta?.alias || [],
    redirect: appearance9B2tU4WReGMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/appearance.vue").then(m => m.default || m)
  },
  {
    name: bridgeJZVH4r3ClLMeta?.name ?? "bridge",
    path: bridgeJZVH4r3ClLMeta?.path ?? "/bridge",
    meta: bridgeJZVH4r3ClLMeta || {},
    alias: bridgeJZVH4r3ClLMeta?.alias || [],
    redirect: bridgeJZVH4r3ClLMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/bridge.vue").then(m => m.default || m)
  },
  {
    name: integrationsX1s50LxNUQMeta?.name ?? "integrations",
    path: integrationsX1s50LxNUQMeta?.path ?? "/integrations",
    meta: integrationsX1s50LxNUQMeta || {},
    alias: integrationsX1s50LxNUQMeta?.alias || [],
    redirect: integrationsX1s50LxNUQMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: profilempmbOOM2vzMeta?.name ?? "profile",
    path: profilempmbOOM2vzMeta?.path ?? "/profile",
    meta: profilempmbOOM2vzMeta || {},
    alias: profilempmbOOM2vzMeta?.alias || [],
    redirect: profilempmbOOM2vzMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: settingsnXXGFi4oVlMeta?.name ?? "settings",
    path: settingsnXXGFi4oVlMeta?.path ?? "/settings",
    meta: settingsnXXGFi4oVlMeta || {},
    alias: settingsnXXGFi4oVlMeta?.alias || [],
    redirect: settingsnXXGFi4oVlMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: confirmi9FD4LdQJtMeta?.name ?? "sign-in-confirm",
    path: confirmi9FD4LdQJtMeta?.path ?? "/sign-in/confirm",
    meta: confirmi9FD4LdQJtMeta || {},
    alias: confirmi9FD4LdQJtMeta?.alias || [],
    redirect: confirmi9FD4LdQJtMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/sign-in/confirm.vue").then(m => m.default || m)
  },
  {
    name: index3NvgM5Kg23Meta?.name ?? "sign-in",
    path: index3NvgM5Kg23Meta?.path ?? "/sign-in",
    meta: index3NvgM5Kg23Meta || {},
    alias: index3NvgM5Kg23Meta?.alias || [],
    redirect: index3NvgM5Kg23Meta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: passwordW0EnsY8NqMMeta?.name ?? "sign-in-password",
    path: passwordW0EnsY8NqMMeta?.path ?? "/sign-in/password",
    meta: passwordW0EnsY8NqMMeta || {},
    alias: passwordW0EnsY8NqMMeta?.alias || [],
    redirect: passwordW0EnsY8NqMMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/sign-in/password.vue").then(m => m.default || m)
  },
  {
    name: set_45password1d5jel0FykMeta?.name ?? "sign-in-set-password",
    path: set_45password1d5jel0FykMeta?.path ?? "/sign-in/set-password",
    meta: set_45password1d5jel0FykMeta || {},
    alias: set_45password1d5jel0FykMeta?.alias || [],
    redirect: set_45password1d5jel0FykMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/sign-in/set-password.vue").then(m => m.default || m)
  },
  {
    name: tariffAkdTSci4zsMeta?.name ?? "tariff",
    path: tariffAkdTSci4zsMeta?.path ?? "/tariff",
    meta: tariffAkdTSci4zsMeta || {},
    alias: tariffAkdTSci4zsMeta?.alias || [],
    redirect: tariffAkdTSci4zsMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/tariff.vue").then(m => m.default || m)
  },
  {
    name: ui_45kitEXADGPxFSDMeta?.name ?? "ui-kit",
    path: ui_45kitEXADGPxFSDMeta?.path ?? "/ui-kit",
    meta: ui_45kitEXADGPxFSDMeta || {},
    alias: ui_45kitEXADGPxFSDMeta?.alias || [],
    redirect: ui_45kitEXADGPxFSDMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/ui-kit.vue").then(m => m.default || m)
  },
  {
    name: visitorsX3iLNNgWjbMeta?.name ?? "visitors",
    path: visitorsX3iLNNgWjbMeta?.path ?? "/visitors",
    meta: visitorsX3iLNNgWjbMeta || {},
    alias: visitorsX3iLNNgWjbMeta?.alias || [],
    redirect: visitorsX3iLNNgWjbMeta?.redirect || undefined,
    component: () => import("/usr/src/app/pages/visitors.vue").then(m => m.default || m)
  }
]