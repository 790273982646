import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl } from '~/api/common'

export const qrApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/qr',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async generate (url: string, type: string) {
      const response = await axiosBase.get('/generate', {
        params: {
          url,
          type
        },
        responseType: 'arraybuffer'
      })

      const contentType = response.headers['content-type']
      const base64String = arrayBufferToBase64(response.data)
      return `data:${contentType};base64,${base64String}`
    }
  }
}

function arrayBufferToBase64 (buffer: ArrayBuffer): string {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}
