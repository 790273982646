import { left, right } from '@sweet-monads/either'
import isObject from 'lodash-es/isObject'
import { RuntimeConfig } from 'nuxt/schema'

export function getApiBaseUrl (config: RuntimeConfig) {
  return process.server
    ? config.API_BASE_URL
    : config.public.API_BASE_URL
}

export const handleErrors = (payload: unknown) => {
  if (
    !isObject(payload) ||
    (isObject(payload) && 'error' in payload)
  ) {
    return left(payload)
  }

  return right((payload as any).data)
}
