import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const userApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/user',
    withCredentials: true,
    validateStatus (status) {
      // When all methods will be rewritten in monads, make this always return true(to don't throw exceptions)
      return status >= 200 && status < 500
    }
  })

  return {
    async changeEmail (email: string) {
      const { data: payload } = await axiosBase.put('/change-email', { email })

      return handleErrors(payload)
    },
    async changeTelegram (telegram: string) {
      const { data: payload } = await axiosBase.put('/change-telegram', { telegram })

      return handleErrors(payload)
    }
  }
}
