import cookie from 'cookie'
import { useApi } from '~/composables/useApi'

interface State {
  testList: any // TODO: types
}

export const useAbTestsStore = defineStore('abTests', {
  state: (): State => ({
    testList: {}
  }),
  actions: {
    // Getters with params
    isTestGroup (testName: string) {
      return this.testList[testName] === 'test'
    },
    isControlGroup (testName: string) {
      return this.testList[testName] === 'control'
    },
    // Actions
    async fetchTests (cookies: any, res: any) {
      const api = useApi()
      const utm = JSON.parse(cookie.parse(cookies || '')?.utm || 'null')
      const getEither = await api.abTestsApi.getTests({
        cookies,
        utm
      })

      getEither.mapRight((tests) => {
        this.SET_TESTS(tests)

        const tenYearsMs = 3156e+8
        res.cookie('ab_tests', JSON.stringify(tests), {
          path: '/',
          expires: new Date(Date.now() + tenYearsMs)
        })
      })
    },
    async refreshTests () {
      const api = useApi()
      const getEither = await api.abTestsApi.refreshTests()

      getEither.mapRight((testList) => {
        this.SET_TESTS(testList)

        if (process.client) {
          const tenYearsMs = 3156e+8
          const expires = new Date(Date.now() + tenYearsMs)
          const name = 'ab_tests'
          const value = JSON.stringify(testList)
          document.cookie = name + '=' + value + '; expires=' + expires + '; path=/'
        }
      })
    },
    // Mutations
    SET_TESTS (tests: any) {
      this.testList = tests
    }
  }
})
