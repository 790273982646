import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl, handleErrors } from '~/api/common'
import type { UserData } from '~/types/subscription'

export const subscriptionsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/subscriptions',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getMySubscription () {
      const { data: payload } = await axiosBase.get('')

      return handleErrors(payload)
    },
    create (subscriptionPlanId: string, userId: string, username: string) {
      return axiosBase.post(
        subscriptionPlanId,
        { userId, username }
      )
    },
    giveDemo (userId: string) {
      return axiosBase.post('give-demo', { userId })
    },
    unsubscribeRecurringPayment (userId: string) {
      return axiosBase.post(
        'task/unsubscribe-from-recurring',
        { userId }
      )
    },
    async getUserRecurring (userData: UserData) {
      const { data: payload } = await axiosBase.get(`/get-user-recurring?userId=${userData.userId}`)

      return handleErrors(payload)
    }
  }
}
