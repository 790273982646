import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl, handleErrors } from '~/api/common'

interface IUpdateSettings {
  id: string;
  whatsappEnabled: boolean;
  whatsappNumber: string;
  whatsappGreetingMessage: string;
  appendWazzupId: boolean;
  telegramEnabled: boolean;
  telegramUsername: string;
  hidden: boolean;
}

interface IRenameSettings {
  settingsId: string;
  name: string;
}

export const settingsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/settings',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getSettings () {
      const { data: payload } = await axiosBase.get('/')

      return handleErrors(payload)
    },
    async updateSettings (updateSettings: IUpdateSettings) {
      const { data: payload } = await axiosBase.post('/update', {
        ...updateSettings
      })

      return handleErrors(payload)
    },
    async addSettings (name: string) {
      const { data: payload } = await axiosBase.post('/add', {
        name
      })

      return handleErrors(payload)
    },
    async renameSettings ({ name, settingsId }: IRenameSettings) {
      const { data: payload } = await axiosBase.post('/rename', {
        settingsId,
        name
      })

      return handleErrors(payload)
    },
    async deleteSettings (settingsId: string) {
      const { data: payload } = await axiosBase.post('/delete', {
        settingsId
      })

      return handleErrors(payload)
    }
  }
}
