import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

interface ICreateInnerEvent {
  userId: string | null;
  name: string;
  details?: Record<string, any>
}

export const statisticsInnerApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/statistics-inner',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async createInnerEvent ({ name, userId, details }: ICreateInnerEvent) {
      const { data: payload } = await axiosBase.post('/create-inner-event', {
        name,
        userId,
        details
      })

      return handleErrors(payload)
    },
    async getCopyWidgetScriptEventsCount () {
      const { data: payload } = await axiosBase.get('/copy-widget-script-events-count')
      return handleErrors(payload)
    },
    async isDemoTariffNotificationSeen () {
      const { data: payload } = await axiosBase.get('/is-demo-tariff-notification-seen')
      return handleErrors(payload)
    }
  }
}
