import { useApi } from '~/composables/useApi'

const DESCRIPTIONS = {
  3000: {
    title: 'Ежемесячный',
    price: '3000 руб.',
    description: '3000 руб. / месяц или 36000 руб. / год'
  },
  25000: {
    title: 'Ежегодный',
    sale: 'Скидка 30%',
    price: '25000 руб.',
    description: '3000 руб. / месяц или 25000 руб. / год'
  }
}

interface State {
  subscriptionPlans: any[]
}

export const useSubscriptionPlansStore = defineStore('subscriptionPlans', {
  state: (): State => ({
    subscriptionPlans: []
  }),
  getters: {
    subscriptionPlansWithDescription: (state) => {
      return state.subscriptionPlans.map((subscription: any) => {
        const description = (DESCRIPTIONS as any)[subscription.amount] // TODO: fix '(DESCRIPTION as any)'
        return {
          ...subscription,
          ...description
        }
      })
    }
  },
  actions: {
    // Actions
    async getSubscriptionPlans () {
      const api = useApi()
      const getEither = await api.subscriptionPlanApi.getAllSubscriptionPlans()

      getEither.mapRight((subscriptionPlans) => {
        this.SET_SUBSCRIPTION_PLANS(subscriptionPlans)
      })

      return getEither
    },
    // Mutations
    SET_SUBSCRIPTION_PLANS (payload: any[]) {
      this.subscriptionPlans = payload
    }
  }
})
