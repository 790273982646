import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

interface IGetVisitorsEvents {
  settingsId: string;
  startDate: Date,
  endDate: Date,
  wazzupId: string;
  deviceType: string;
}

export const visitorsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/visitors',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getVisitorsEvents ({ settingsId, startDate, endDate, wazzupId, deviceType }: IGetVisitorsEvents) {
      const { data: payload } = await axiosBase.post('/get-by-settings', {
        settingsId,
        startDate,
        endDate,
        wazzupId,
        deviceType
      })

      return handleErrors(payload)
    },
    async getDialogEventExist (settingsId: string) {
      const { data: payload } = await axiosBase.get(`/get-dialog-event-exists?settingsId=${settingsId}`)

      return handleErrors(payload)
    }
  }
}
