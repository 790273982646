import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

interface IGetThreads {
  settingsId: string;
  page: number;
  dateStart: string;
  dateEnd: string;
  unit: string;
  deviceType: string;
  wazzupId: string;
  tz: string;
}

export const statisticsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/statistics',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getThreads (getThreadsParams: IGetThreads) {
      const { data: payload } = await axiosBase.get('/threads', {
        params: getThreadsParams
      })

      return handleErrors(payload)
    },
    async getInitialThreads (getInitialThreadsParams: Omit<IGetThreads, 'page'>) {
      const { data: payload } = await axiosBase.get('/initial-threads', {
        params: getInitialThreadsParams
      })

      return handleErrors(payload)
    },
    async getThreadEvents ({ settingsId, threadId }: { settingsId: string; threadId: string }) {
      const { data: payload } = await axiosBase.get('/events', {
        params: {
          settingsId,
          threadId
        }
      })

      return handleErrors(payload)
    },
    async getChartEvents (getChartEventsParams: Omit<IGetThreads, 'page'>) {
      const { data: payload } = await axiosBase.get('/chart-events', {
        params: getChartEventsParams
      })

      return handleErrors(payload)
    },
    async getSummary (getSummaryParams: Omit<IGetThreads, 'page' | 'unit' | 'wazzupId'>) {
      const { data: payload } = await axiosBase.get('/summary', {
        params: getSummaryParams
      })

      return handleErrors(payload)
    },
    async getIsWazzupIntegrated (settingsId: string) {
      const { data: payload } = await axiosBase.get('/wazzup-integration', {
        params: {
          settingsId
        }
      })

      return handleErrors(payload)
    },
    async getIsRoistatIntegrated (settingsId: string) {
      const { data: payload } = await axiosBase.get('/roistat-integration', {
        params: {
          settingsId
        }
      })

      return handleErrors(payload)
    },
    async checkHasWidgetThreeClicks (settingsId: string) {
      const { data: payload } = await axiosBase.get('/has-widget-three-clicks', { params: { settingsId } })
      return handleErrors(payload)
    }
  }
}
