export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const router = useRouter()

  const isProd = config.public.NODE_ENV === 'production'

  if (!isProd) {
    window.ym = (...args: any[]) => {
      // eslint-disable-next-line no-console
      console.info('ym called with', ...args)
    }
    return
  }

  const YM_ID = config.public.YANDEX_YM_ID
  const tryInitPrivateMetrika = privateMetrikaFactory()

  // inject Yandex Metrica
  /* eslint-disable */
  window['ym'] =
    window['ym'] ||
    function () {
      // @ts-ignore
      (window['ym'].a = window['ym'].a || []).push(arguments);
    };
  // @ts-ignore
  window['ym'].l = 1 * new Date();

  window.createCommonYmEvent = (name, options) => {
    window.ym(YM_ID, name, options)
  }

  setTimeout(() => {
    (function (m, e, t, r, i, k, a) {
      // @ts-ignore
      (k = e.createElement(t)),
        // @ts-ignore
        (k.async = true),
        // @ts-ignore
        (k.src = r),
        // @ts-ignore
        (k.onload = () => {
          window.ym(YM_ID, 'init', {
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true
          });

          setTimeout(tryInitPrivateMetrika, 0)
        });
      // @ts-ignore
      e.body.appendChild(k);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');
  }, 2000);
  /* eslint-enable */

  router.afterEach((to) => {
    window.ym(YM_ID, 'hit', to.fullPath)
  })

  function privateMetrikaFactory () {
    let initialized = false

    return function tryInit () {
      if (initialized) {
        return
      }

      window.ym(YM_ID, 'init', {
        webvisor: true,
        clickmap: true,
        accurateTrackBounce: true,
        trackLinks: true
      })
      initialized = true
    }
  }
})

declare global {
  interface Window {
    createPrivateYmEvent (...args: any[]): any,
    createCommonYmEvent (...args: any[]): any,
    ym (...args: any[]): any,
  }
}
