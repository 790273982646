import { useSettingsStore } from './settings'
import { useApi } from '~/composables/useApi'

const INTEGRATION_TYPES = {
  YM: 'ym',
  GA: 'ga',
  CALL_TOUCH: 'callTouch',
  CO_MAGIC: 'coMagic',
  UIS: 'uis'
}

interface State {
  integrations: any // { [Settings.id]: Integration[] }
}

export const useIntegrationsStore = defineStore('integrations', {
  state: (): State => ({
    integrations: { }
  }),
  getters: {
    currentIntegrations: (state) => {
      const settingsStore = useSettingsStore()
      const currentSettingsId = settingsStore.currentSettings?.id
      return state.integrations[currentSettingsId] ?? []
    }
  },
  actions: {
    // Actions
    async getIntegrations () {
      const api = useApi()
      const getEither = await api.integrationsApi.getIntegrations()

      getEither.mapRight((integrations) => {
        this.SET_INTEGRATIONS(integrations)
      })

      return getEither
    },

    async createIntegration ({ type, ym, ga, coMagic, uis, callTouch, callTracking }: any) {
      if (type === INTEGRATION_TYPES.YM && (ym.counter.length < 5 || ym.counter.length > 9) && (ym.apiCounter.length < 5 || ym.apiCounter.length > 9) && ym.apiToken < 59) { return }
      if (type === INTEGRATION_TYPES.GA && (ga.counter.length < 6 && (ga.apiToken < 22 && ga.apiCounter < 6))) { return }
      if (type === INTEGRATION_TYPES.CO_MAGIC && ((!coMagic.isApi && coMagic.counter.length < 32) || (coMagic.isApi && coMagic.token.length < 40))) { return }
      if (type === INTEGRATION_TYPES.UIS && ((!uis.isApi && uis.counter.length < 32) || (uis.isApi && uis.token.length < 40))) { return }
      if (type === INTEGRATION_TYPES.CALL_TOUCH && callTouch.counter.length < 8 && callTouch.token.length < 45) { return }

      const settingsStore = useSettingsStore()
      const currentSettingsId = settingsStore.currentSettings?.id
      const api = useApi()

      const addEither = await api.integrationsApi.createIntegration({
        settingsId: currentSettingsId,
        type,
        ym,
        ga,
        callTouch,
        coMagic,
        uis,
        callTracking
      })

      addEither.mapRight((integration) => {
        this.ADD_INTEGRATION(integration)
      })

      return addEither
    },
    async deleteIntegration (integration: any) {
      const api = useApi()
      const deleteEither = await api.integrationsApi.deleteIntegration(integration.id)

      deleteEither.mapRight(() => {
        this.DELETE_INTEGRATION(integration)
      })

      return deleteEither
    },
    // Mutations
    SET_INTEGRATIONS (payload: any) {
      this.integrations = payload
    },
    ADD_INTEGRATION (payload: any) {
      if (!this.integrations[payload.settings]) {
        // Vue.set(this.integrations, payload.settings, []) <- was, but gives ts error
        this.integrations[payload.settings] = []
      }

      this.integrations[payload.settings].push(payload)
    },
    DELETE_INTEGRATION (payload: any) {
      const currentIntegrations = this.integrations[payload.settings]
      this.integrations[payload.settings] = currentIntegrations.filter((i: any) => i.id !== payload.id)
    }
  }
})
