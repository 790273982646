import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const filesApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/files',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async uploadFiles ({ files }: { files: File[] }) {
      const formData = new FormData()
      files.forEach(file => formData.append('files', file))

      const { data: payload } = await axiosBase.post('/upload', formData)

      return handleErrors(payload)
    }
  }
}
