import dayjs from 'dayjs'
import { useApi } from '~/composables/useApi'
import { SubscriptionInfo } from '~/types/subscription'

interface State {
  subscription: SubscriptionInfo | null,
  subscriptionInfo: any
}

export const useSubscriptionStore = defineStore('subscription', {
  state: (): State => ({
    subscription: null,
    subscriptionInfo: null
  }),
  getters: {
    isSubscribed: state => state.subscription !== null && dayjs(state.subscription.dueDate) > dayjs()
  },
  actions: {
    // Actions
    async getSubscription () {
      const api = useApi()
      const getEither = await api.subscriptionsApi.getMySubscription()

      getEither.mapRight((subscription) => {
        const dd = dayjs(subscription?.dueDate)
        this.SET_SUBSCRIPTION(dayjs() > dd ? null : subscription)
        this.SET_SUBSCRIPTION_INFO(subscription)
      })

      return getEither
    },
    async giveDemo (userId: string) {
      const api = useApi()
      const { data = null } = await api.subscriptionsApi.giveDemo(userId)

      this.SET_SUBSCRIPTION(data)
      this.SET_SUBSCRIPTION_INFO(data)

      return data
    },
    startTimer () {
      if (!this.subscription) {
        return
      }

      const dd = dayjs(this.subscription?.dueDate)
      const tick = setInterval(() => {
        if (dayjs() > dd) {
          this.SET_SUBSCRIPTION(null)
          clearInterval(tick)
        }
      }, 1000)
    },
    // Mutations
    SET_SUBSCRIPTION (payload: any) {
      this.subscription = payload
    },
    SET_SUBSCRIPTION_INFO (payload: any) {
      this.subscriptionInfo = payload
    }
  }
})
