export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const router = useRouter()

  const isProd = config.public.NODE_ENV === 'production'

  if (!isProd) {
    window.gtag = (...args) => {
      // eslint-disable-next-line no-console
      console.debug('gtag called with', ...args)
    }
  }

  const GOOGLE_GA_ID = config.public.GOOGLE_GA_ID

  /* eslint-disable */
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_GA_ID}`
    script.async = true
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
  function gtag(){window.dataLayer.push(arguments);}
  // @ts-ignore
  gtag('js', new Date());
  /* eslint-enable */

  window.gtag = gtag

  router.afterEach((to) => {
    window.gtag('config', GOOGLE_GA_ID, {
      page_path: to.path,
      optimize_id: 'OPT-N547NLW'
    })
  })
})

declare global {
  interface Window {
    gtag (...args: any[]): any,
    dataLayer: any[]
  }
}
