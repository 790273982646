export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const isProd = config.public.NODE_ENV === 'production'
  const GTM_ID = config.public.GOOGLE_GTM_ID

  if (!isProd) {
    return
  }

  /* eslint-disable */
  // @ts-ignore
  window.dataLayer = window.dataLayer || []

  setTimeout(() => {
    (function(w,d,s,l,i) {
      w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],

        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', GTM_ID);
  }, 2000)
  /* eslint-enable */
})
