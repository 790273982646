import { RuntimeConfig } from '@nuxt/schema'
import axios, { AxiosRequestConfig } from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const abTestsApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/ab-tests',
    withCredentials: true,
    validateStatus (status) {
      // When all methods will be rewritten in monads, make this always return true(to don't throw exceptions)
      return status >= 200 && status < 500
    }
  })

  return {
    async getTests ({ cookies, utm }: any) {
      const config: AxiosRequestConfig = { headers: {} }
      if (cookies) {
        config.headers!.Cookie = cookies
      }

      const { data: payload } = await axiosBase.post('/compute-user-tests', { utm }, config)

      return handleErrors(payload)
    },
    async refreshTests () {
      const { data: payload } = await axiosBase.post('/compute-user-tests')

      return handleErrors(payload)
    }
  }
}
