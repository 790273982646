import { useRuntimeConfig } from 'nuxt/app'
import { setCookie } from '~/utils/cookies'
import { useStore } from '~/store/index'
import { useAbTestsStore } from '~/store/abTests'
import { useApi } from '~/composables/useApi'
import type { JwtUserData } from '~/types/jwtUserData'

interface State {
  user: any, // TODO: types for user
  editorFirstEnter: boolean | null,
  firstSectionEnter: string | null
}

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    user: null,
    editorFirstEnter: null,
    firstSectionEnter: null
  }),
  getters: {
    isAuthenticated: state => !!state.user
  },
  actions: {
    // Actions
    async loginVerifySms (authCode: string, recaptchaToken: string, username:string) {
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()
      const api = useApi()

      const loginEither = await api.authApi.loginVerifySms({ authCode, recaptchaToken, username })

      return loginEither.asyncMap(async () => {
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state)
        await abTestsStore.refreshTests()
      })
    },
    async loginByPassword (password: string, recaptchaToken: string, username: string) {
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()
      const api = useApi()

      const loginEither = await api.authApi.loginByPassword({ password, recaptchaToken, username })

      return loginEither.asyncMap(async () => {
        setCookie('__num', loginEither.value.id, 365)
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state)
        await abTestsStore.refreshTests()
      })
    },
    async loginByToken (userId: string, token: string) {
      const api = useApi()
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()

      const loginEither = await api.authApi.loginByToken({
        userId,
        token
      })

      return loginEither.asyncMap(async () => {
        setCookie('__num', loginEither.value.id, 365)
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state)
        await abTestsStore.refreshTests()
      })
    },
    async authViaTelegram (payloadFromTelegram: any, userInfo: any) {
      const api = useApi()
      const rootStore = useStore()
      const abTestsStore = useAbTestsStore()

      const authViaTelegramEither = await api.authApi.authViaTelegram({
        payloadFromTelegram,
        userInfo
      })

      await authViaTelegramEither.asyncMap(async () => {
        const cookiesRaw = decodeURIComponent(document.cookie)
        await rootStore.initEditor(rootStore.$state)
        await abTestsStore.refreshTests()
      })
      return authViaTelegramEither
    },
    async logout () {
      const api = useApi()
      await api.authApi.logout()
      const config = useRuntimeConfig()

      setCookie('__num', '0', -1)
      setCookie(config.public.COOKIE_NAME_JWT_ACCESS_TOKEN, '', -1)
      setCookie(config.public.COOKIE_NAME_JWT_REFRESH_TOKEN, '', -1)

      this.SET_USER(null)
    },
    async getUser (isBridgeRoute: boolean = false) {
      if (isBridgeRoute) { return }
      const api = useApi()
      const config = useRuntimeConfig()

      const isStaging = config.public.IS_STAGING
      const isDevelopment = config.public.NODE_ENV === 'development'

      const redirectToAuthUrl =
        isDevelopment
          ? new URL('http://localhost:5001/passport/widget').toString() // url for local
          : new URL(
            isStaging
              ? `${config.public.AUTH_FRONTEND_URL}/passport/widget` // url for dev
              : `${config.public.AUTH_FRONTEND_URL}/passport/widget` // url for prod
          ).toString()

      try {
        const response = await api.authApi.getMe()
        const jwtUser = response?.value?.user as JwtUserData

        if (response.isLeft()) {
          console.error('Failed to get user. Response: ', response)
          console.log('Redirecting to: ', redirectToAuthUrl)
          window.location.href = redirectToAuthUrl
          return
        }

        const username = (jwtUser.phoneNumber || jwtUser.email) as string
        const userRecurring = await this.checkUserRecurring(jwtUser.id)
        this.SET_USER({
          id: jwtUser.id,
          phoneNumber: jwtUser.phoneNumber,
          email: jwtUser.email,
          username,
          hasRecurring: userRecurring
        })
      } catch (e) {
        console.error('Failed to get user. Error: ', e)
        console.log('Redirecting to: ', redirectToAuthUrl)
        window.location.href = redirectToAuthUrl
      }
    },
    async isWazzupUser (username: string) {
      const api = useApi()
      const either = await api.authApi.isWazzupUser(username)

      return either
    },
    async performMigration (username: string, recaptchaToken: string) {
      const api = useApi()
      const either = await api.authApi.performMigration(username, recaptchaToken)

      return either
    },
    async changeUsernameVerify (authCode: string, newUsername: string) {
      const api = useApi()
      const changeEither = await api.authApi.changeUsernameVerify({ authCode, newUsername })

      changeEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return changeEither
    },
    async changeUserEmail (email: string) {
      const api = useApi()
      const changeEither = await api.userApi.changeEmail(email)

      changeEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return changeEither
    },
    async changeUserTelegram (telegram: string) {
      const api = useApi()
      const changeEither = await api.userApi.changeTelegram(telegram)

      changeEither.mapRight((user) => {
        this.SET_USER(user)
      })

      return changeEither
    },
    unsubscribeUserFromRecurring () {
      this.SET_USER_RECURRING(false)
    },
    async checkEditorFirstEnter (userId: string) {
      const api = useApi()
      const isFirstEnter = await api.authApi.checkFirstEnter(userId)

      isFirstEnter.mapRight((value) => {
        this.SET_EDITOR_FIRST_ENTER(value)
      })

      return isFirstEnter
    },
    async checkFirstSection (userId: string, sectionName: string) {
      const api = useApi()
      const FirstSection = await api.authApi.checkFirstSectionEnter({ userId, sectionName })

      FirstSection.mapRight((value) => {
        this.SET_FIRST_SECTION_ENTER(value)
      })

      return FirstSection
    },
    async checkUserRecurring (userId: string) {
      const api = useApi()
      const userRecurring = await api.subscriptionsApi.getUserRecurring({ userId })

      return userRecurring.value
    },
    // Mutations
    SET_USER (payload: any) {
      this.user = payload
    },
    SET_USER_RECURRING (payload: any) {
      this.user.hasRecurring = payload
    },
    SET_EDITOR_FIRST_ENTER (payload: boolean) {
      this.editorFirstEnter = payload
    },
    SET_FIRST_SECTION_ENTER (payload: string) {
      this.firstSectionEnter = payload
    }
  }
})
