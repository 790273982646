import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { getApiBaseUrl, handleErrors } from '~/api/common'

export const onboardingApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/onboarding',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getUserOnboarding () {
      const { data: payload } = await axiosBase.get('/list')

      return handleErrors(payload)
    },
    async passOnboardingStep ({ feature, step }: { feature: string, step: number }) {
      const { data: payload } = await axiosBase.post('/pass-step', {
        feature, step
      })

      return handleErrors(payload)
    }
  }
}
