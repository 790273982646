import { RuntimeConfig } from '@nuxt/schema'
import axios from 'axios'
import { handleErrors, getApiBaseUrl } from '~/api/common'

export const bridgeApi = (config: RuntimeConfig) => {
  const axiosBase = axios.create({
    // @ts-ignore
    baseURL: getApiBaseUrl(config) + '/bridge',
    withCredentials: true,
    validateStatus: () => true
  })

  return {
    async getBridgeData (wid: string, settingsId: string, actionId: string, personalOfferIndex: number) {
      const { data: payload } = await axiosBase.get('/getData', {
        params: {
          wid,
          settingsId,
          actionId,
          poi: personalOfferIndex
        }
      })

      return handleErrors(payload)
    }
  }
}
