import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig> {
  // https://router.vuejs.org/api/interfaces/routeroptions.html#routes
  routes (_routes) {
    return [
      ..._routes,
      {
        path: '/',
        redirect: { name: 'settings' }
      },
      {
        path: '/register',
        redirect: { name: 'signIn' }
      },
      {
        path: '/register/confirm',
        redirect: { name: 'signInConfirm' }
      },
      {
        path: '/register/set-password',
        redirect: { name: 'signInSetPassword' }
      },
      {
        path: '/login',
        redirect: { name: 'signIn' }
      },
      {
        path: '/login/confirm',
        redirect: { name: 'signInConfirm' }
      },
      {
        path: '/account/login/:userId/:token',
        name: 'loginByToken',
        component: () => import('~/pages/account/login/token.vue').then(r => r.default || r)
      }
    ]
  }
}
