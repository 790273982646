import { acceptHMRUpdate, defineStore } from 'pinia'
import { useAuthStore } from '~/store/auth'
import { useSettingsStore } from '~/store/settings'
import { useIntegrationsStore } from '~/store/integrations'
import { useSubscriptionPlansStore } from '~/store/subscriptionPlans'
import { useSubscriptionStore } from '~/store/subscription'
import { useActionsStore } from '~/store/actions'

export const useStore = defineStore('root', {
  actions: {
    async nuxtClientInit (isBridgeRoute: boolean = false) {
      const store = useStore()

      await store.initEditor(store, isBridgeRoute)
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async initEditor (store: any, isBridgeRoute: boolean = false) {
      const authStore = useAuthStore()
      const settingsStore = useSettingsStore()
      // const onboardingStore = useOnboardingStore()
      const integrationsStore = useIntegrationsStore()
      const subscriptionPlansStore = useSubscriptionPlansStore()
      const subscriptionStore = useSubscriptionStore()
      const actionsStore = useActionsStore()

      settingsStore.resetCurrentSettings(null)

      // get user before everything
      await authStore.getUser(isBridgeRoute)

      // fetch all secondary data
      await settingsStore.getSettings()
      await integrationsStore.getIntegrations()
      await subscriptionPlansStore.getSubscriptionPlans()
      await subscriptionStore.getSubscription()
      await actionsStore.getMyActions({ })
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot))
}
